export default defineNuxtRouteMiddleware(async (to, _from) => {
  const authStore = useAuthStore();
  const { isApp, loggedIn } = storeToRefs(authStore);

  const { userData } = storeToRefs(useUserStore());

  if (loggedIn.value) {
    const userType = userData.value.type;
    const postLoginPage = userType === 'shipper' ? '/dashboard/post-load' : '/dashboard/loads';
    return await navigateTo(postLoginPage);
  }

  if (isApp.value && !['/login', '/register', '/forgot-password', '/reset-password'].includes(to.fullPath)) {
    return await navigateTo('/login');
  }
});
